import * as React from 'react';
import { graphql, navigate } from 'gatsby';
import { useEffect } from 'react';

const Page404 = () => {
	useEffect(() => {
		navigate('/404-pl/');
	}, []);
	return <p>404</p>;
};

export default Page404;

export const query = graphql`
	query {
		all404Data: allCraftPojedynczePodstronyPojedynczePodstronyEntry(filter: { remoteId: { eq: 662 }, language: { eq: "pl" } }) {
			nodes {
				slug
				remoteId
				language
				wybierzModul {
					__typename
					... on Craft_moduly_errorPageIlustracje_Entry {
						errorPageIlustracje {
							__typename
							... on Craft_errorPageIlustracje_ilustracje_BlockType {
								__typename
								ilustracjaDesktop {
									url
								}
								ilustracjaMobile {
									url
								}
							}
							... on Craft_errorPageIlustracje_kolorLoga_BlockType {
								__typename
								kolorLoga
							}
						}
					}
				}
			}
		}
		globalLogo: allCraftLogoGlobalSet(filter: { language: { eq: "pl" } }) {
			nodes {
				logoAnimacja {
					url
				}
			}
		}
		globalCookies: allCraftCookiesGlobalSet(filter: { language: { eq: "pl" } }) {
			nodes {
				cookiesIkona {
					url
				}
				cookiesTekst
				cookiesNapisNaPrzycisku
			}
		}
		footer: allCraftStopkaGlobalSet(filter: { language: { eq: "pl" } }) {
			nodes {
				... on Craft_stopka_GlobalSet {
					stopka {
						... on Craft_stopka_lewaKolumna_BlockType {
							tekst
						}
						... on Craft_stopka_prawaKolumna_BlockType {
							ikona {
								url
							}
							email
							nazwaFirmy
							adres
						}
						... on Craft_stopka_dolnaNawigacja_BlockType {
							wybierzPodstrony {
								slug
								title
							}
						}
						... on Craft_stopka_logoPleo_BlockType {
							linkDoStrony
							logo {
								url
							}
						}
						__typename
					}
				}
			}
		}

		menuModuleData: allCraftMenuMenuEntry(filter: { language: { eq: "pl" } }) {
			nodes {
				nazwaPodstrony
				opisPodstrony
				level
				remoteChildren {
					slug
					title
				}
				wybierzPodstrone {
					slug
				}
			}
		}

		submenuModuleData: allCraftSubmenuSubmenuEntry(filter: { language: { eq: "pl" } }) {
			nodes {
				nazwaPodstrony
				wybierzPodstrone {
					slug
				}
			}
		}

		socialMediaModuleData: allCraftSocialMediaGlobalSet(filter: { language: { eq: "pl" } }) {
			nodes {
				socialMedia {
					... on Craft_socialMedia_socialMedia_BlockType {
						linkUrl
						nazwa
						logo {
							url
						}
						logoZielone {
							url
						}
						widoczneWStopce
						widoczneWMenu
					}
				}
			}
		}
	}
`;
